import { Component,OnInit  } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.scss'],
})
export class MainAppComponent implements OnInit{
  constructor(protected authService:AuthService) {

    this.authService.isAuthenticated = true;
  }
  ngOnInit() {
    debugger
    this.authService.initializeFromStorage();
  }
}
