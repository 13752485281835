import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent {
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = Number(params['id']);
      debugger;

      if (!isNaN(id)) {
        // Redirect with the validated number id parameter
        this.router.navigate(['/login'], { queryParams: { id } });
      } else {
        // Redirect without the id parameter or handle the case when id is not a number
        this.router.navigate(['/login']);
      }
    });

  }
}
