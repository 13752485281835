import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appCopyrightYear]',
})
export class CopyrightYearDirective {

  @HostBinding('innerHTML') year: string;

  constructor() {
    debugger
    this.year = new Date().getFullYear().toString();
  }

}
