// auth.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isjwtToken: any;
  constructor(private router: Router, private encryptDecryptService: EncryptDecryptService) { }

  canActivate(): boolean {
    debugger;
    const encryptedData = localStorage.getItem('u.');

    if (encryptedData !== null) {
      const decryptedData = this.encryptDecryptService.decryptUsingAES256(encryptedData);
      let userDetails = JSON.parse(decryptedData);
      this.isjwtToken = userDetails.token;

      if (this.isjwtToken) {
        const decodedToken: any = this.encryptDecryptService.decodeJwtToken(this.isjwtToken);

        // Check if the token is expired
        if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
          // Redirect to the login page
          this.router.navigate(['/login']); // Replace '/login' with your actual login route
          return false;  // Abort the request since the token is expired
        } else {
          // Token is not expired, add it to the headers
          return true;
        }
      }
    }

    // No JWT token found or some other error occurred
    this.router.navigate(['/login']);
    return false;
  }
}
