import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
  showScrollButton: boolean = false;
  scrollTriggerPercentage: number = 15; // Adjust this value as needed

  arrowChecks = [
    true, true, true, true
  ]
  constructor(private elementRef: ElementRef) { }
  arrowFunction(index: any) {
    this.arrowChecks[index] = !this.arrowChecks[index];

  }



  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = (window.pageYOffset / (document.body.scrollHeight - window.innerHeight)) * 100;
    this.showScrollButton = scrollPosition >= this.scrollTriggerPercentage;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  scrollToSection(sectionId: string): void {
    const element = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  closeMenu() {
    let navbarToggle = document.querySelector('.navbar-toggler');
    let navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarToggle.classList.add('collapsed');
      navbarCollapse.classList.remove('show');
    }
  }
}
