import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './templates/login/login.component';
import { mainGuard } from './guards/main.guard';
import { MainAppComponent } from './templates/main-app/main-app.component';
import { RedirectComponent } from './templates/redirect/redirect.component';
import { LandingPageComponent } from './templates/landing-page/landing-page.component';
const routes: Routes = [
  {
    path: 'home',
    component: LandingPageComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'patient',
    component: MainAppComponent,
    loadChildren: () =>
      import('./modules/patient.module').then((m) => m.PatientModule),
    canActivate: [mainGuard],
  },
  {
    path: ':id',
    component: RedirectComponent, // Redirect component handles redirection to '/login'
  },
  {
    path: '',
    component: RedirectComponent, // Redirect empty path directly to login
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    component: RedirectComponent, // Add this route for the RedirectComponent
  },
  { path: '**', component: RedirectComponent }

  // Other routes go here
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
