export class ORMLogedInPatient {
  userId: number;
  practiceId: number;
  userName: string;
  password: string;
  email: string;
  firstName: string;
  userLName: string;
  userMname: string;
  resetPasswordDate: string;
  userFullName: string;
  userType: string;

  constructor() {
    this.userId = 0;
    this.practiceId = 0;

    this.userName = '';
    this.password = '';
    this.email = '';
    this.firstName = '';
    this.userLName = '';
    this.userMname = '';
    this.resetPasswordDate = '';
    this.userFullName = '';
    this.userType = '';
  }
}
