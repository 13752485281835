<div class="container-fluid">
  <div class="row no-gutter">
    <!-- The content half -->
    <div class="col-md-12 bg-light bg-img">
      <div class="login d-flex align-items-center">
        <!-- Demo content-->
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-xl-5 mx-auto logoimage"
              style="height: 26em; max-width: 400px"
            >
              <img class="mb-3 mt-3" src="./assets/images/logo.svg" alt="" />
              <div style="border: 1px solid #25436785; padding: 15px">
                <p
                  style="
                    font-size: 0.7rem;
                    font-weight: 600;
                    color: #254367;
                    text-wrap: wrap;
                    text-align: center;
                  "
                >
                  Welcome To iRCM Pay.
                </p>

                <!-- Conditionally hide/show form based on patient ID -->
                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                  style="padding-top: 10px"
                >
                  <!-- Conditionally show the input field for phone number -->
                  <!-- Conditionally show the input field for phone number -->
                  <div
                    *ngIf="!isPatientId"
                    class="form-group mb-1"
                    style="height: 3rem"
                  >
                    <input
                      id="inputUsername"
                      type="text"
                      placeholder="(000) 000-0000"
                      formControlName="username"
                      class="form-control shadow-sm px-4 py-2"
                      (input)="onPhoneInput($event)"
                      maxlength="14"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('username')?.invalid &&
                          loginForm.get('username')?.touched,
                      }"
                    />
                    <div
                      *ngIf="
                        (loginForm.get('username')?.invalid &&
                          loginForm.get('username')?.touched) ||
                        this.alertmessage
                      "
                      class="error-text"
                    >
                      {{ this.infomessage }}
                    </div>
                  </div>

                  <!-- Conditionally show the buttons based on phone number input or patient ID -->
                  <div
                    *ngIf="phoneEntered || isPatientId"
                    style="text-align: center; margin-top: 10px"
                  >
                    <button
                      type="button"
                      class="btn btn-block text-uppercase mb-2 shadow-sm btn-state"
                      [ngClass]="{
                        'btn-active active': activeButton === 'dob',
                      }"
                      (click)="showDOBField()"
                    >
                      Login With DOB
                    </button>
                    <span class="m-1"></span>
                    <button
                      type="button"
                      class="btn btn-block text-uppercase mb-2 shadow-sm btn-state"
                      [ngClass]="{
                        'btn-active active': activeButton === 'otp',
                      }"
                      (click)="showOTPField()"
                    >
                      Request SMS Code
                    </button>
                  </div>

                  <!-- Conditionally show the DOB field -->
                  <div
                    *ngIf="showDOB"
                    class="form-group mb-1"
                    style="height: 3rem"
                  >
                    <input
                      id="inputPassword"
                      type="password"
                      minlength="8"
                      maxlength="8"
                      placeholder="MMDDYYYY"
                      formControlName="password"
                      class="form-control shadow-sm px-4 py-2"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('password')?.invalid &&
                          loginForm.get('password')?.touched,
                      }"
                    />
                  </div>

                  <!-- Conditionally show the OTP field -->
                  <div
                    *ngIf="showOTP"
                    class="form-group mb-1"
                    style="height: 3rem"
                  >
                    <input
                      id="otp"
                      type="text"
                      placeholder="Enter OTP"
                      formControlName="otp"
                      minlength="6"
                      maxlength="6"
                      class="form-control shadow-sm px-4 py-2"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('otp')?.invalid &&
                          loginForm.get('otp')?.touched,
                      }"
                    />
                  </div>

                  <!-- Show submit button when either DOB or OTP is shown -->
                  <span *ngIf="unauthorizedError" class="error-message">
                    Credentials are invalid.
                  </span>
                  <div
                    *ngIf="showDOB || showOTP"
                    style="text-align: center; margin-top: 10px"
                  >
                    <button
                      type="submit"
                      class="btn btn-block text-uppercase mb-2 shadow-sm"
                      style="
                        background-color: #254367;
                        color: white;
                        border-radius: 0px;
                        font-size: small;
                        width: 100%;
                      "
                      (click)="continuebtn()"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- End -->
      </div>
    </div>
    <!-- End -->
    <!-- The image half -->
    <!-- <div class="col-md-7 d-none d-md-flex bg-image">
      <div
        class="col-lg-5 col-xl-4 col-xxl-5 custhgt2 aos-init aos-animate"
        data-aos="fade-zoom-in"
        data-aos-delay="1000"
        id="rightWidgetSection"
      >
        <div class="row" style="padding: 0px;">
          <img class="p-0"
          src="../../../assets/images/right-img.jpg"
          alt="Access Statement"
        />          
        </div>
      </div>
    </div>  -->
    <footer class="text-center footer">
      &copy; <span appCopyrightYear></span>, iRCM.All Rights Reserved.
    </footer>
  </div>
</div>
