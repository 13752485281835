import { Component, HostListener, OnInit } from '@angular/core';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  practiceName: string;
  patientName: string;
  isMobileViewVisible
  constructor(public authService: AuthService) {
    this.checkScreenSize()
  }
  ngOnInit() {
    // Subscribe to the observable to get updates
    this.authService.userDetails$.subscribe((userDetails) => {
      debugger
      this.practiceName = userDetails.practiceName;
      this.patientName = userDetails.patientName;
    });
  }
  // HostListener to detect window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }
  // Function to check screen size and toggle views
  checkScreenSize() {
    const screenWidth = window.innerWidth;
    // Assuming threshold is 768px for mobile view
    if (screenWidth <= 768) {
      this.isMobileViewVisible = true;
    } else {
      this.isMobileViewVisible = false;
    }
  }
  // logout function
  logOut() {
    this.authService._logOut();
  }
  closeMenu() {
    let navbarToggle = document.querySelector('.navbar-toggler');
    let navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarToggle.classList.add('collapsed');
      navbarCollapse.classList.remove('show');
    }
  }
}
